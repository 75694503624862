import store from '../store'
import { Toast } from "vant";
import { addHardwareLog } from '@/api/api.js'
import UA from 'ua-device'
//当点击按钮超过三次执行
function posErrThree() {
    Toast.clear();
    store.commit("changelng", store.state.openOrScheduleId.userLongitude || 0)
    store.commit("changelat", store.state.openOrScheduleId.userLatitude || 0)
    // getDistance(
    //     store.state.lng,
    //     store.state.lat,
    //     store.state.userInfo.terminalLongitude,
    //     store.state.userInfo.terminalLatitude
    // )
    translateBaidu(true)
    if (store.state.lng === 0) {
        Toast({
            message: '您的设备无法获取到定位',
            duration: 2000,
        });
    }
}

//显示当前地址
function getAddress(lng, lat) {
    try {
    const point = new BMapGL.Point(lng, lat);
    //将坐标转换为地址
    var geoc = new BMapGL.Geocoder();
    geoc.getLocation(
        point,
        function (res) {
            // console.log(res);
            store.commit('changePositionLoading', false)
            if (res.point.lng) {
                store.commit("changeNowAddress", res.address || '定位获取成功')
            }
        }
        //   经度范围默认100
        //   {
        //     poiRadius: 800,
        //   }
    );
    Toast.clear();
    // //坐标结束之后计算一下距离
    getDistance(
        store.state.lng,
        store.state.lat,
        store.state.userInfo.terminalLongitude,
        store.state.userInfo.terminalLatitude
    )
    } catch (error) {
        handleLog('百度获取地址失败', error)
    }
}

//转换百度坐标
function translateBaidu(isBaidu = false) {
    //需要先将坐标转换为百度的
    let lat = store.state.lat;
    let lng = store.state.lng;
    //如果是url传过来的坐标不需要转百度直接解析地址就可以了
    if (isBaidu) {
        getAddress(lng, lat)
        return
    }
    try {        
    const pointBak = new BMapGL.Point(lng, lat);
    const convertor = new BMapGL.Convertor();
    convertor.translate([pointBak], 1, 5, function (resPoint) {
        if (resPoint && resPoint.points && resPoint.points.length > 0) {
            lng = resPoint.points[0].lng;
            lat = resPoint.points[0].lat;
            store.commit("changelng", lng)
            store.commit("changelat", lat)
            // console.log(store.state.lng);
        }
        getAddress(lng, lat)
    });
    } catch (error) {
        handleLog('百度坐标转换失败', error)
    }
}
function resetPosition() {
    // 距离也重置
    store.commit('changeDistance', 0)
    store.commit('changelng', 0)
    store.commit('changelat', 0)
    store.commit('changeNowAddress', null)
}


//获取gps定位 
export function browserPos(posCurrent) {
    store.commit('changePositionLoading', true)
    // 判断是否支持 获取本地位置
    Toast.loading({
        message: '定位中请稍后...',
        forbidClick: true,
        duration: 0
    });
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function (res) {
                try {
                if (posCurrent <= 0 && store.state.lng === 0) {//当用户连续点击了三次定位都失败了,而且用户开启了定位 则取前一个页面url传过来的定位信息
                    // console.log('取了传过来的定位信息');
                    posErrThree()
                    return
                }
                // console.log(res); // 需要的坐标地址就在res中
                let lat = res.coords.latitude;
                let lng = res.coords.longitude;
                store.commit("changelng", lng)//有可能百度解析未成功则暂时先使用谷歌经纬度
                store.commit("changelat", lat)//有可能百度解析未成功则暂时先使用谷歌经纬度
                // console.log(store.state.lng);
                translateBaidu()
                } catch (error) {
                    handleLog('浏览器定位成功', error)
                }
            },
            function (err) {
                resetPosition() // 如定位失败 先清除定位信息 避免有人中途把定位关了可以用之前的定位
                store.commit("resetSize")
                if (posCurrent <= 0 && store.state.lng === 0) {//当用户连续点击了三次定位都失败了,而且用户开启了定位 则取前一个页面url传过来的定位信息
                    console.log('取了传过来的定位信息');
                    posErrThree()
                    return
                }
                store.commit('changePositionLoading', false)
                //如定位失败发请求记录失败者的手机信息
                var output = new UA(navigator.userAgent) //UA获取到的对象
                var sss = navigator.userAgent.split(';')
                //判断UA里边有没有Build信息，通过这个拿到安卓的具体机型
                var i = -1
                sss.forEach((element, index) => {
                    if (element.includes('Build/')) {
                        i = index
                    }
                })
                let newMobile //如果是安卓手机返回安卓的型号
                if (i > -1) {
                    newMobile = sss[i].substring(0, sss[i].indexOf('Build/'))
                }
                let ip = ''
                try {
                    ip = returnCitySN.cip
                } catch { }
                addHardwareLog({
                    funTitle: '定位失败' + store.state.taskDetailsInfo.taskSubCode + store.state.taskDetailsInfo.title,
                    userAgents: navigator.userAgent,
                    resolvingPower: `${screen.width}x${window.screen.height}`,
                    ip: ip,
                    userCode: store.state.openOrScheduleId.openId,//传的字段是userCode实际传的是openId
                    browser: output.browser.name + ' ' + output.browser.version.original,
                    os: output.os.name + ' ' + output.os.version.original,
                    device: output.device.identified ? output.device.model : newMobile,
                    locErr: 'code:' + err.code + ' message:' + err.message
                }).then(() => {
                    Toast({
                        message: '定位已被禁用,请开启',
                        duration: 2000,
                    });
                }).catch(() => {
                    Toast({
                        message: '定位已被禁用,请开启',
                        duration: 2000,
                    });
                })
                // console.log('err.code', err.code);//3是超时  禁用可能是1也可能是2   
            },
            {
                // enableHighAcuracy: true, // 指示浏览器获取高精度的位置，默认为false
                timeout: 3000, // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
                maximumAge: 3000 // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
            }
        );
    } else {
        Toast('该浏览器不支持定位')
    }
}

//计算距离 返回米
export function getDistance(lng1, lat1, lng2, lat2) {
    // console.log('计算距离',lng1, lat1, lng2, lat2);
    var BMap = require('bmaplib').BMap;
    var BMapLib = require('bmaplib').BMapLib;
    var bp1 = new BMap.Point(lng1, lat1);
    var bp2 = new BMap.Point(lng2, lat2);
    if (store.state.userInfo.terminalLongitude == null || store.state.userInfo.terminalLatitude == null) {
        store.commit("changeDistance", -1)
        store.commit("resetSize")
        return -1
    } else {
        store.commit("changeDistance", parseInt(BMapLib.GeoUtils.getDistance(bp1, bp2)))
        store.commit("resetSize")
        return parseInt(BMapLib.GeoUtils.getDistance(bp1, bp2))
    }
}

const handleLog = (title, err) => {
    Toast.clear()
    //如定位失败发请求记录失败者的手机信息
    var output = new UA(navigator.userAgent) //UA获取到的对象
    var sss = navigator.userAgent.split(';')
    //判断UA里边有没有Build信息，通过这个拿到安卓的具体机型
    var i = -1
    sss.forEach((element, index) => {
        if (element.includes('Build/')) {
            i = index
        }
    })
    let newMobile //如果是安卓手机返回安卓的型号
    if (i > -1) {
        newMobile = sss[i].substring(0, sss[i].indexOf('Build/'))
    }
    let ip = ''
    try {
        ip = returnCitySN.cip
    } catch { }
    addHardwareLog({
        funTitle: title + '-----' + store.state.taskDetailsInfo.taskSubCode + store.state.taskDetailsInfo.title,
        userAgents: navigator.userAgent,
        resolvingPower: `${screen.width}x${window.screen.height}`,
        ip: ip,
        userCode: store.state.openOrScheduleId.openId,//传的字段是userCode实际传的是openId
        browser: output.browser.name + ' ' + output.browser.version.original,
        os: output.os.name + ' ' + output.os.version.original,
        device: output.device.identified ? output.device.model : newMobile,
        locErr: 'code:' + err.code + ' message:' + err.message
    })
}
